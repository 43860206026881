import React from "react"
import LangContext from "@contexts/lang"
import { SanityFaq } from "@utils/types"
import Arrow from "@images/icons/arrow.svg"
import s from "./faq.module.scss"
import { BaseBlockContent } from "@components/globals/baseBlockContent/baseBlockContent"
import CTA from "../cta"

const FAQ: React.FC<{
  faq: SanityFaq
  index: number
  expanded: boolean
  onExpanded(index: number): void
}> = ({ faq, index, expanded, onExpanded }) => {
  const { lang } = React.useContext(LangContext)

  const { question, answer, cta } = faq

  const handleQuestionClick = () => {
    onExpanded(index - 1)
  }

  return (
    <div className={s.faq}>
      <div className={s.question} onClick={() => handleQuestionClick()}>
        <p>
          {index}. {question[lang]}
        </p>
        <span className={`${s.arrow} ${expanded && s.expanded}`}>
          <Arrow />
        </span>
      </div>
      {expanded && (
        <>
          <div className={s.answer}>
            <BaseBlockContent blocks={answer[lang]} />
          </div>
          {cta && <CTA cta={cta} color="#742FC4" withArrow />}
        </>
      )}
    </div>
  )
}
export default FAQ
