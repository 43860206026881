import React from "react"
import LangContext from "@contexts/lang"
import { BEPlan, cta_styles, SanityCta } from "@utils/types"
import s from "./pricingPlan.module.scss"
import FireIcon from "@images/icons/fire.svg"
import CheckIcon from "@images/icons/green_check.svg"
import CTA from "../cta"
import useTranslateField from "@hooks/useTranslateField"
import formatMessage from "@utils/texts"
import { pricePerMonth } from "@utils/pricePerMonth"
import { DEFAULT_COUNTRY_CODE } from "@utils/constants"
import trackFacebookEvent from "@utils/trackFacebookEvent"


const PricingPlan: React.FC<{
  plan: BEPlan
  mostPopular?: boolean
  activePrice: boolean
  showCTA?: boolean
  showFeature?: boolean
}> = ({ plan, showCTA = true, mostPopular, showFeature = false }) => {
  const { lang } = React.useContext(LangContext)
  const translateField = useTranslateField()

  const {
    titleAr,
    titleEn,
    descriptionAr,
    descriptionEn,
    featuresAr,
    featuresEn,
    id,
    price,
    interval,
    currency: { titleAr: currencyAr, titleEn: currencyEn },
  } = plan

  const currencyUnit = translateField(currencyAr, currencyEn)

  const features = translateField(featuresAr, featuresEn)
  const cta: SanityCta = {
    label: {
      en: "Contact us",
      ar: "ابدأ هُنا",
    },
    href: "/register",
    style: cta_styles.button,
  }

  const monthlyPrice = pricePerMonth(interval, price)
  const { countryCode } = React.useContext(LangContext)

  const arabicInterval = interval => {
    switch (interval) {
      case "month":
        return "تدفع شهرياً"
      case "quarter":
        return "تدفع ربع سنوياً"
      case "annual":
        return "تدفع سنوياً"
      default:
        return `Billed ${interval}ly`
    }
  }

  const paymentIntervalInArabic = arabicInterval(interval)

  function trackContact() {
    window?.Intercom && window?.Intercom("show")
    trackFacebookEvent("Contact", {
      country: countryCode || DEFAULT_COUNTRY_CODE,
    })
  }

  return (
    <div className={`${s.pricingPlan} `}>
      <h4 className={s.headline}>
        {showFeature
          ? formatMessage({ id: "includedInPlan", lang })
          : translateField(titleAr, titleEn)}
      </h4>
      {!showFeature && (
        <p className={s.subheadline}>
          {lang === "ar" ? paymentIntervalInArabic : `Billed ${interval}ly`}
        </p>
      )}
      {!showFeature && (
        <p className={s.price}>
          {lang === "ar"
            ? `${monthlyPrice} ${currencyUnit}`
            : `${currencyUnit} ${monthlyPrice}`}
          <span>/ {formatMessage({ id: "month", lang })}</span>
        </p>
      )}
      {showFeature && features.length > 0 && (
        <div className={s.features}>
          {features.map((feature, i) => {
            return (
              <div className={s.feature} key={i}>
                <CheckIcon className={s.icon} />
                <p>{feature}</p>
              </div>
            )
          })}
        </div>
      )}
      {showCTA && (
        //${mostPopular && s.mostPopular}
        <>
          {/* <CTA cta={cta} className={`${s.cta} `} params={`?planId=${id}`} /> */}
          <button className={`${s.cta} `} onClick={trackContact}>
            {lang === "ar" ? "تواصل معنا" : "Contact us"}
          </button>
        </>
      )}
    </div>
  )
}

export default PricingPlan
