import React, { FC } from "react"
import LangContext from "@contexts/lang"
import { BEPlan, BEPlans, SanityPricingSection } from "@utils/types"
import s from "./PricingSection.module.scss"
import PricingPlan from "@components/common/pricingPlan"
import Checkbox from "@components/common/checkbox"
import Select from "@components/globals/Select"
import formatMessage from "@utils/texts"

const PricingSection: FC<{
  pricingPlansSection: SanityPricingSection
  plans: BEPlans
}> = ({ plans, pricingPlansSection }) => {
  const { lang, countryCode, setCountryCode } = React.useContext(LangContext)
  const [activePrice, setActivePrice] = React.useState(true)

  const { headline, subheadline } = pricingPlansSection

  const handleChange = c => {
    setCountryCode(c)
  }

  const handlePricingChange = (active: boolean) => {
    setActivePrice(active)
  }

  if (!plans?.plans.length || plans?.plans.length === 0) {
    return <></>
  }

  const filteredPlans = plans.plans.filter(plan => {
    const isForCurrentCountry = plan.countryCode === countryCode
    const showOnWebsite = plan.showOnWebsite
    return isForCurrentCountry && showOnWebsite
  })

  const isRecommened = (plan: BEPlan) => {
    return plan.titleEn.toLocaleLowerCase().includes("premium")
  }

  return (
    <div className={s.pricingSection}>
      <div className={s.texts}>
        <p className={s.headline}>{headline[lang]}</p>
        <h2 className={s.subheadline}>{subheadline[lang]}</h2>
      </div>
      <div className={s.plansSelectors}>
        {/* <Checkbox active={activePrice} onChange={handlePricingChange} /> */}
        <div className={s.countrySelector}>
          <Select
            onChange={handleChange}
            placeholder="country"
            value={countryCode}
          >
            {/* <Select.Option value="BH">
              {formatMessage({ id: "bahrain", lang })}
            </Select.Option> */}
            <Select.Option value="EG">
              {formatMessage({ id: "egypt", lang })}
            </Select.Option>
            {/* <Select.Option value="JO">
              {formatMessage({ id: "jordan", lang })}
            </Select.Option> */}
            <Select.Option value="KW">
              {formatMessage({ id: "kuwait", lang })}
            </Select.Option>
            {/* <Select.Option value="OM">
              {formatMessage({ id: "oman", lang })}
            </Select.Option> */}
            {/* <Select.Option value="QA">
              {formatMessage({ id: "qatar", lang })}
            </Select.Option> */}
            <Select.Option value="SA">
              {formatMessage({ id: "ksa", lang })}
            </Select.Option>
            {/* <Select.Option value="AE">
              {formatMessage({ id: "uae", lang })}
            </Select.Option> */}
          </Select>
        </div>
      </div>
      <div className={s.pricingPlans}>
        {filteredPlans.length > 0 &&
          filteredPlans.map((plan, i) => (
            <PricingPlan
              plan={plan}
              key={i}
              activePrice={activePrice}
              mostPopular={isRecommened(plan)}
              showCTA={false}
            />
          ))}
      </div>
      <div className={s.pricingPlans}>
        {filteredPlans.length > 0 && (
          <PricingPlan
            plan={filteredPlans[0]}
            activePrice={activePrice}
            showFeature={true}
            showCTA={true}
          />
        )}
      </div>
    </div>
  )
}

export default PricingSection
