import React, { FC } from "react"
import LangContext from "@contexts/lang"
import { SanityFaqsSection } from "@utils/types"
import s from "./QuestionsSection.module.scss"
import FAQ from "@components/common/faq"

const QuestionsSection: FC<{ faqsSection: SanityFaqsSection }> = ({
  faqsSection,
}) => {
  const { lang } = React.useContext(LangContext)
  const [expanded, setExpanded] = React.useState(0)

  const { title, faqs } = faqsSection

  const handleExpanded = (index: number) => {
    setExpanded(expanded === index ? null : index)
  }

  return (
    <div className={`${s.questionsSection}`}>
      {title && <h2>{title[lang]}</h2>}
      {faqs.length && (
        <div className={s.faqs}>
          {faqs.map((faq, i) => (
            <FAQ
              faq={faq}
              index={i + 1}
              key={i}
              expanded={expanded === i}
              onExpanded={handleExpanded}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default QuestionsSection
