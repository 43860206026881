import React, { FC, useState } from "react"
import { graphql } from "gatsby"
import { SanityPage, BEPlans, sanity_typename } from "@utils/types"
import LangContext from "@contexts/lang"
import countryContentFilter from "@utils/countryContentFilter"
//Components
import PricingSection from "@components/pricingPage/PricingSection"
import QuestionsSection from "@components/pricingPage/QuestionsSection"
import SEO from "@components/common/seo/seo"
/* import useSearchParams from "@hooks/useSearchParams" */

const PricingPage: FC<{
  data: { pricingPage: SanityPage; plans: BEPlans }
  location: string
}> = ({ data: { pricingPage, plans }, location }) => {
  const { lang, countryCode } = React.useContext(LangContext)

  const pricingPlansSection = countryContentFilter(
    pricingPage,
    countryCode,
    sanity_typename.SanityPricingPlansSection
  )[0]

  const faqsSection = countryContentFilter(
    pricingPage,
    countryCode,
    sanity_typename.SanityFaqsSection
  )[0]

  return (
    <>
      <SEO title={pricingPage.title[lang]} location={location} />
      <PricingSection plans={plans} pricingPlansSection={pricingPlansSection} />
      <QuestionsSection faqsSection={faqsSection} />
    </>
  )
}

export default PricingPage

export const query = graphql`
  {
    pricingPage: sanityPage(slug: { current: { eq: "/pricing" } }) {
      title {
        en
        ar
      }
      content {
        ...SanityPricingPlansSection
        ...SanityFaqsSection
      }
    }
    plans {
      plans {
        currency {
          #   decimals
          titleAr
          titleEn
        }
        countryCode
        descriptionAr
        descriptionEn
        discountTextAr
        discountTextEn
        featuresAr
        featuresEn
        id
        interval
        paymentMethods
        position
        price
        titleAr
        titleEn
        showOnWebsite
      }
    }
  }
`
